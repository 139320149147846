import Upload from '@rsuite/icons/legacy/Upload'
import {GraphQLError} from 'graphql'
import React, {useState} from 'react'
import {
  Button,
  ButtonToolbar,
  DatePicker,
  Form,
  List,
  Loader,
  Notification,
  Stack,
  Tag,
  toaster,
} from 'rsuite'
import {
  CopyDbDirection,
  ImportType,
  useAutoImportPanelMembersInfoQuery,
  useAutoUploadOptionsMutation,
  useCopyDbMutation,
  useReindexMutation,
  useRevertPanelMembersMutation,
  useTriggerAutoUploadPanelMembersMutation,
  useUploadPanelMembersMutation,
} from '../../../api'
import {FileDropInput} from '../common/fileDropInput'
import {SpecModalView} from './panelMemberInfoModal'
import {ApolloError} from '@apollo/client'

function importTypeMap(importType: ImportType) {
  switch (importType) {
    case ImportType.FileUpload:
      return 'File upload'

    default:
      return importType
  }
}

export function CustomViewImporter() {
  const [file, setFile] = useState<File | null>(null)
  const [uploadFile, {loading}] = useUploadPanelMembersMutation()
  const [mutateOptions, {loading: loadingOptions}] = useAutoUploadOptionsMutation()
  const [triggerImport, {loading: triggerImportLoading}] =
    useTriggerAutoUploadPanelMembersMutation()
  const {data, refetch} = useAutoImportPanelMembersInfoQuery()
  const [revertPanelMember, {loading: loadingRevert}] = useRevertPanelMembersMutation()
  const isSomethingLoading = loading || triggerImportLoading || loadingRevert || loadingOptions

  function handleError(error: unknown) {
    toaster.push(
      <Notification
        closable
        type="error"
        header={(error as ApolloError).toString()}
        duration={5000}></Notification>,
      {placement: 'topEnd'}
    )
  }

  function handleResult(
    errors: readonly GraphQLError[] | undefined,
    errors2?: readonly (string | null)[] | undefined
  ) {
    if (errors) {
      toaster.push(
        <Notification
          closable
          type="error"
          header={errors.toString()}
          duration={5000}></Notification>,
        {placement: 'topEnd'}
      )
    } else if (errors2 && errors2.length > 0) {
      toaster.push(
        <Notification
          closable
          type="error"
          header={errors2?.join('\n')}
          duration={5000}></Notification>,
        {placement: 'topEnd'}
      )
    } else {
      toaster.push(
        <Notification
          closable
          type="success"
          header="Operation successful"
          duration={5000}></Notification>,
        {placement: 'topEnd'}
      )
    }
    refetch()
  }

  async function onSubmit() {
    try {
      const {errors, data} = await uploadFile({
        variables: {
          input: {file, filename: 'test'},
        },
      })
      handleResult(errors, data?.extensions.uploadPanelMembers.errors)
    } catch (error) {
      handleError(error)
    }
  }

  async function onSubmitTrigger() {
    const {errors, data} = await triggerImport()
    try {
      handleResult(errors, data?.extensions.triggerAutoUploadPanelMembers.errors)
    } catch (error) {
      handleError(errors)
    }
  }

  async function onRestoreTrigger(id: string) {
    try {
      const {errors, data} = await revertPanelMember({
        variables: {
          id,
        },
      })
      handleResult(errors, data?.extensions.revertPanelMembers.errors)
    } catch (error) {
      handleError(error)
    }
  }

  async function handleDrop(files: File[]) {
    if (files.length === 0) return

    const file = files[0]
    setFile(file)
  }

  let timeString: Date | undefined
  if (data?.extensions.autoImportPanelMembersInfo.importTime) {
    try {
      timeString = new Date(data.extensions.autoImportPanelMembersInfo.importTime)
    } catch (error) {
      //
    }
  }

  return (
    <div>
      <h2>Panel Member Auto Importer</h2>
      <h4>Source</h4>
      <a
        href="https://stdatahubexportprod.blob.core.windows.net/exports/PanelMemberSearch/PanelMemberSearch.csv"
        target="_blank"
        rel="noreferrer">
        stdatahubexportprod.blob.core.windows.net/exports/PanelMemberSearch/PanelMemberSearch.csv
      </a>
      <br />
      <br />
      <h4>Specification</h4>
      <SpecModalView />
      <br />
      <h4>Import Time</h4>
      <DatePicker
        value={timeString}
        format="HH:mm"
        onChange={(date) => {
          mutateOptions({variables: {importTime: date ? date.toISOString() : null}})
            .then(() => {
              return refetch()
            })
            .catch(console.error)
        }}
      />
      <br />
      <br />
      <h4>Interval</h4>
      {data?.extensions.autoImportPanelMembersInfo.importInterval}
      <br />
      <br />
      <h4>Action</h4>
      <Form>
        <Form.Group>
          <ButtonToolbar>
            <Button
              disabled={isSomethingLoading}
              onClick={() => {
                onSubmitTrigger()
              }}>
              Trigger Import
            </Button>
          </ButtonToolbar>
        </Form.Group>
      </Form>
      <h4>History</h4>
      <List style={{maxHeight: '400px'}}>
        <List.Item>
          <Stack>
            <Stack.Item style={{width: '220px'}}>
              <b>Import Date:</b>
            </Stack.Item>
            <Stack.Item style={{width: '220px'}}>
              <b>Import Type:</b>
            </Stack.Item>
            <Stack.Item style={{width: '220px'}}>
              <b>Import Status:</b>
            </Stack.Item>
            <Stack.Item>
              <b>Action:</b>
            </Stack.Item>
          </Stack>
        </List.Item>
        {data?.extensions.autoImportPanelMembersInfo.imports
          .map((importInfo) => {
            if (!importInfo) {
              return null
            }
            return (
              <List.Item key={importInfo.id}>
                <Stack>
                  <Stack.Item style={{width: '220px'}}>
                    <a href={`/content/panelMemberImport/edit/${importInfo.id}/0`}>
                      {`${new Date(importInfo.date).toLocaleDateString('de-CH')} ${new Date(
                        importInfo.date
                      ).toLocaleTimeString('de-CH')}`}
                    </a>
                  </Stack.Item>
                  <Stack.Item style={{width: '220px'}}>
                    {importTypeMap(importInfo.importType)}
                  </Stack.Item>
                  <Stack.Item style={{width: '220px'}}>
                    {importInfo.errors.length === 0 && importInfo.validationErrors.length === 0
                      ? 'Success'
                      : 'Failed'}
                  </Stack.Item>
                  <Stack.Item>
                    <Button
                      size="md"
                      block
                      disabled={
                        isSomethingLoading ||
                        !(
                          importInfo.errors.length === 0 && importInfo.validationErrors.length === 0
                        )
                      }
                      onClick={() => onRestoreTrigger(importInfo.id)}>
                      Restore
                    </Button>
                  </Stack.Item>
                </Stack>
              </List.Item>
            )
          })
          .filter((i) => i)}
      </List>
      <br />
      <br />
      <h4>Legacy File Importer</h4>
      <Form>
        <Form.Group>
          <Form.ControlLabel>Upload CSV</Form.ControlLabel>
          <div
            style={{
              maxWidth: '250px',
              height: '160px',
            }}>
            <FileDropInput
              icon={loading ? <Loader /> : <Upload />}
              text={loading ? undefined : 'drag csv here'}
              disabled={loading}
              onDrop={handleDrop}
            />
          </div>

          {file ? (
            <>
              <br></br>
              <br></br>
              <Tag
                closable
                onClose={() => {
                  setFile(null)
                }}>
                {file.name}
              </Tag>
            </>
          ) : null}
        </Form.Group>
        <Form.Group>
          <ButtonToolbar>
            <Button
              appearance={file ? 'primary' : 'default'}
              disabled={!file || loading}
              onClick={() => {
                onSubmit().catch((e) => {
                  console.error(e)
                })
              }}>
              Submit
            </Button>
          </ButtonToolbar>
        </Form.Group>
      </Form>
    </div>
  )
}

export function CustomViewReindex() {
  const [done, setDone] = useState(false)
  const [reindex, {error, loading}] = useReindexMutation()

  async function onSubmit() {
    await reindex({})

    if (error) {
      toaster.push(
        <Notification
          closable
          type="error"
          header={error.toString()}
          duration={5000}></Notification>,
        {placement: 'topEnd'}
      )
    } else {
      setDone(true)
      toaster.push(
        <Notification
          closable
          type="success"
          header="Indexing started"
          duration={5000}></Notification>,
        {placement: 'topEnd'}
      )
    }
  }

  return (
    <div>
      <h1>Reindex Search</h1>
      <br></br>
      <br></br>
      <Form>
        <Form.Group>
          <ButtonToolbar>
            <Button
              disabled={done || loading}
              onClick={() => {
                onSubmit().catch((e) => {
                  console.error(e)
                })
              }}>
              Reindex
            </Button>
          </ButtonToolbar>
        </Form.Group>
      </Form>
    </div>
  )
}

export function CustomViewCopyData() {
  const [done, setDone] = useState(false)
  const [copyDB, {error, loading}] = useCopyDbMutation()

  async function onSubmit(direction: CopyDbDirection) {
    await copyDB({
      variables: {
        copyDBDirection: direction,
      },
    })

    if (error) {
      toaster.push(
        <Notification
          closable
          type="error"
          header={error.toString()}
          duration={5000}></Notification>,
        {placement: 'topEnd'}
      )
    } else {
      setDone(true)
      toaster.push(
        <Notification
          closable
          type="success"
          header="Copy data started"
          duration={5000}></Notification>,
        {placement: 'topEnd'}
      )
    }
  }

  return (
    <div>
      <h1>Copy Data</h1>
      <br></br>
      <br></br>
      <Form>
        <Form.Group>
          <ButtonToolbar>
            <Button
              disabled={done || loading}
              onClick={() => {
                onSubmit(CopyDbDirection.ProdToStaging).catch((e) => {
                  console.error(e)
                })
              }}>
              Copy Data to Staging
            </Button>

            {process.env.NODE_ENV === 'development' ? (
              <>
                <Button
                  disabled={done || loading}
                  onClick={() => {
                    onSubmit(CopyDbDirection.ProdToLocal).catch((e) => {
                      console.error(e)
                    })
                  }}>
                  Copy Data from prod to local MongoDB
                </Button>

                <Button
                  disabled={done || loading}
                  onClick={() => {
                    onSubmit(CopyDbDirection.ProdToDevelopment).catch((e) => {
                      console.error(e)
                    })
                  }}>
                  Copy Data from prod to Development (Atlas)
                </Button>
              </>
            ) : null}
          </ButtonToolbar>
        </Form.Group>
      </Form>
    </div>
  )
}
