import React, {useState} from 'react'
import {Button, ButtonToolbar, List, Modal, Placeholder, Stack} from 'rsuite'

const fields = [
  {
    field: 'EvaluationBodyId',
    type: 'string',
    mandatory: 'yes',
    example: '31863d42-4ee2-4c08-87dc-6aa025109952',
  },
  {field: 'EvaluationBodyNumber', type: 'number', mandatory: 'yes', example: '300422'},
  {
    field: 'EvaluationBodyName',
    type: 'string',
    mandatory: 'no (ignored)',
    example: 'Research Council - Biology and Medicine',
  },
  {field: 'PersonName', type: 'string', mandatory: 'yes', example: 'Hans Muster'},
  {field: 'AcademicDegree_En', type: 'string', mandatory: 'no', example: 'Prof.'},
  {field: 'AcademicDegree_De', type: 'string', mandatory: 'no', example: 'Prof.'},
  {field: 'AcademicDegree_Fr', type: 'string', mandatory: 'no', example: 'Prof.'},
  {field: 'PersonNumber', type: 'string', mandatory: 'yes', example: '537859'},
  {
    field: 'IsResearchCouncilMember',
    type: 'boolean (True/False case insensitive)',
    mandatory: 'yes',
    example: 'True',
  },
  {field: 'University_En', type: 'string', mandatory: 'no', example: 'University of Zurich - ZH'},
  {field: 'University_De', type: 'string', mandatory: 'no', example: 'Universität Zürich - ZH'},
  {field: 'University_Fr', type: 'string', mandatory: 'no', example: 'Université de Zurich - ZH'},
  {field: 'Website', type: 'string', mandatory: 'no', example: 'https://www.example.com/'},
  {
    field: 'Function_En',
    type: 'string',
    mandatory: 'no',
    example: 'Member without evaluation function',
  },
  {
    field: 'Function_De',
    type: 'string',
    mandatory: 'no',
    example: 'Mitglied ohne Evaluationsfunktion',
  },
  {
    field: 'Function_Fr',
    type: 'string',
    mandatory: 'no',
    example: 'Membre sans fonction d’évaluation',
  },
  {
    field: 'MembershipValidFrom',
    type: `Extended ISO 8601 `,
    mandatory: 'yes',
    example: '2022-01-01 00:00:00.0000000',
  },
  {
    field: 'MembershipValidUntil',
    type: 'Extended ISO 8601 ',
    mandatory: 'no',
    example: '2022-01-01 00:00:00.0000000',
  },
  {
    field: 'EvaluationBodyName_En',
    type: 'string',
    mandatory: 'no',
    example: 'Steering Committee - "Horizon Europe Transitional Measures"',
  },
  {
    field: 'EvaluationBodyName_De',
    type: 'string',
    mandatory: 'no',
    example: 'Leitungsgruppe - "Horizon Europe Transitional Measures"',
  },
  {
    field: 'EvaluationBodyName_Fr',
    type: 'string',
    mandatory: 'no',
    example: 'Comité de direction - "Horizon Europe Transitional Measures"',
  },
  {
    field: 'EvaluationBodyType',
    type: 'string',
    mandatory: 'no',
    example: 'Evaluationskommissionen',
  },
  {
    field: 'EvaluationBodyPublishedFromDate',
    type: 'Extended ISO 8601',
    mandatory: 'yes',
    example: '2022-01-01 00:00:00.0000000',
  },
  {
    field: 'EvaluationBodyPublishedUntilDate',
    type: 'Extended ISO 8601',
    mandatory: 'no',
    example: '2022-01-01 00:00:00.0000000',
  },
  {field: 'EvaluationBodySortNumber', type: 'number', mandatory: 'yes', example: '60'},
  {field: 'FunctionSortNumber', type: 'number', mandatory: 'yes', example: '10'},
]

export function SpecModalView() {
  const [open, setOpen] = useState(false)
  const handleOpen = () => {
    setOpen(true)
  }
  const handleClose = () => setOpen(false)

  return (
    <>
      <ButtonToolbar>
        <Button size="md" onClick={() => handleOpen()}>
          Open Specification
        </Button>
      </ButtonToolbar>

      <Modal size="lg" open={open} onClose={handleClose}>
        <Modal.Header>
          <Modal.Title>Panel Member CSV Importer Specification</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <h2>Panel Member CSV Importer</h2>
          <h4>Charset:</h4>
          utf-8
          <br />
          <br />
          <h4>Delimiter:</h4>
          ;
          <br />
          <br />
          <h4>Date Format:</h4>
          Extended ISO 8601 format: <b>YYYY-MM-DD</b>. The time part is ignored.
          <br />
          <br />
          <h4>Columns</h4>
          <List>
            <List.Item>
              <Stack>
                <Stack.Item style={{width: '260px'}}>
                  <b>Field:</b>
                </Stack.Item>
                <Stack.Item style={{width: '200px'}}>
                  <b>Type:</b>
                </Stack.Item>
                <Stack.Item style={{width: '200px'}}>
                  <b>Mandatory:</b>
                </Stack.Item>
                <Stack.Item style={{width: '300px'}}>
                  <b>Example:</b>
                </Stack.Item>
              </Stack>
            </List.Item>
            {fields.map((field) => {
              return (
                <List.Item key={field.field}>
                  <Stack>
                    <Stack.Item style={{width: '260px'}}>{field.field}</Stack.Item>
                    <Stack.Item style={{width: '200px'}}>{field.type}</Stack.Item>
                    <Stack.Item style={{width: '200px'}}>{field.mandatory}</Stack.Item>
                    <Stack.Item style={{width: '300px'}}>{field.example}</Stack.Item>
                  </Stack>
                </List.Item>
              )
            })}
          </List>
        </Modal.Body>
        <Modal.Footer>
          <br />
          <Button onClick={handleClose} appearance="primary">
            Ok
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  )
}
